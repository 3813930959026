@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #fff;
  --mainColor: #2E52FF;
}


::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--mainColor);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--white)
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Montserrat', sans-serif;
}
body {
  width: 100vw;
  overflow-x: hidden;
}

.z-220 {
  z-index: 220;
}

.light {
  font-weight: 300;
}
.bolder {
  font-weight: bolder;
}

.bold {
  font-weight: bold;
}

.small-txt {
  font-size: .8rem;
}

.big-txt {
  font-size: 3.4rem;
}

.main-txt {
  color: var(--mainColor) ;
}

.white-txt {
  color: var(--white);
}


.fs-12 {
  font-size: .7rem;
}
.fs-14 {
  font-size: .8rem;
}

.fs-16 {
  font-size: .9rem;
}

.fs-18 {
  font-size: 1.1rem;
}

.fs-24 {
  font-size: 2rem;
}

.fs-28 {
  font-size: 2.5rem;
}


@media (max-width: 1000px) {
  .fs-12 {
    font-size: .6rem;
  }
  .fs-14 {
    font-size: .7rem;
  }
  .fs-16 {
    font-size: .8rem;
  }
  .fs-18 {
    font-size: .9rem;
  }
  .fs-24 {
    font-size: 1.5rem;
  }
  .fs-28 {
    font-size: 1.8rem !important;
  }
  
}

.main-bg {
  background-color: var(--mainColor);
}

.none-bg {
  background-color: none;
}

.white-bg {
  background-color: var(--white);
}

.main-bor {
  border: 1px solid var(--mainColor);
}


/* =========== LOADER  =========== */

.loaderContainer {
  z-index: 110;
}

.loader {
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
}

.loader .circle {
  --color: #2E52FF;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%, 25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%, 50% {
    transform: scale(1) rotate(180deg);
  }

  70%, 75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%, 100% {
    transform: scale(1) rotate(360deg);
  }
}



/*==============INPUTS =================*/

.input-group {
  position: relative;
 }
 
 .input, .input-err {
  border: solid 1.5px #9e9e9e;
  border-radius: 1rem;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: var(--mainColor);
  transition: border 150ms cubic-bezier(0.4,0,0.2,1);
  width: 100%;
 }

 .input {
  border: 1px solid var(--mainColor);
 }
 
 .user-label {
  position: absolute;
  left: 15px;
  color: var(--mainColor);
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4,0,0.2,1);
  padding: 0px .4em;
 }

 .user-label-err {
    color: red !important;
 }
 
 .input:focus, input:valid {
  outline: none;
  border: 1.5px solid var(--mainColor);
 }

 .input-err:focus, .input-err:valid {
  outline: none;
  border: 1.5px solid red;
 }
 
 .input:focus ~ label, input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: var(--white);
  padding: 0 .2em;
  color: var(--mainColor);
 }

 @media (max-width: 900px) {
  .input:focus ~ label, input:valid ~ label {
      background-color: var(--baseColor);
    }
 }


/*=============== Nav =======================*/

.mb-nav {
  z-index: 120;
}

nav {
  z-index: 100;
}

nav img {
  height: 70px;
  width: 70px;
}

nav ul li {
  transition: all .3s ease-in-out;
}

nav ul li:hover {
  color: var(--mainColor);
  border-bottom: 1px solid var(--mainColor);
  font-weight: bolder;
}


@media (max-width: 1000px) {

  .mb-hide {
    display: none;
  }

  nav {
    justify-content: space-between;
    width: 100%;
  }

  nav .hidden {
    display: block;
  }

  nav .links {
    width: 0px !important;
    display: none !important;
  }

  nav ul li {
    display: none;
  }

  nav img {
    height: 40px;
    width: 40px;
  }
}



/*=============== Landing =======================*/

.landing img {
  width: fit-content;
  object-fit: cover;
}


@media (max-width: 1000px) {
  .landing {
    margin-top: 6em;
    padding: 0px 1em;
    gap: 1em;
    flex-direction: column;
  }

  .landing h1 {
    font-size: 2.5rem;
  }

  .landing p {
    font-size: .7rem;
  }

  .landing .left, .landing .right  {
    width: 100%;
  }

  .landing img {
    width: 90vw;
    height: 30vh;
    border-radius: 0px;
  }

  .mb-flex-col {
    flex-direction: column;
  }
}


/*================== Objetivo =======================*/

@media (max-width: 1000px) {
  .objetivos {
    flex-direction: column-reverse;
    margin-top: 6em;
    padding: 3em 1em;
    text-align: justify;
    gap: 1em;
  }

  .objetivos .right, .objetivos .left {
    width: 100%;
  }

}


/*================== Servicios =======================*/


.serviceCard {
  max-width: 25vw;
  z-index: 1;
}

.serviceCard .icon {
  min-width: 6em;
}

.servicios .title {
  background-size: cover;
  background-position-y: -15em;
  background-repeat: no-repeat;
  background-image: url(../public/serviciosBanner.webp);
  height: 15em;
  width: 100%;
}

.servicios .opacity {
  background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 1000px) {
  .servicios .title {
    height: 10em;
    background-position-y:  0em;
  }

  .mb-fs-12 {
    font-size: .6rem;
  }

  .serviceCard {
    min-width: 100%;
  }



  .serviceCard .cardTitle {
    padding-left: 0em;
  }

  .serviceCardsContainer {
    flex-wrap: nowrap;
  }

}

/*================== Contacto =======================*/

@media (max-width: 1000px) {
  .contacto {
    margin-top: 5em;
  }
  
  .contactForm {
    width: 90%;
  }
}


/* ============= FOOTER ================= */
footer{
  width: 100vw;
  height: fit-content;
  padding-top: 1em;
  padding-bottom: .5em;
}

footer .container{
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 3vw;
}
footer  .rowSocials {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content:left;
}

footer  .rowSocials i{
  font-size: 20px;
  margin-right: 2vw;
}

footer .container {
  margin-top: 3vh;
}

footer .container .row {
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
footer .container .row span{
  font-size: 12px;
}

footer .container .row span:hover{
  -webkit-text-stroke: 1px var(--primaryTxt);
  filter: drop-shadow(0 0 23px var(--primaryTxt));
  cursor: pointer;
}


@media (max-width: 1000px) {
  footer .container{
    height: fit-content;
    grid-template-columns: repeat(1,1fr); 
    row-gap: 5vh; 
  }
 }


 .icon {
  width: 8rem !important;
  height: 8rem !important;
 }


 @media (max-width: 1000px) {
  .icon {
    width: 4rem !important;
    height: 4rem !important;
    z-index: 300;
   }
 }

 .z-200 {
  z-index: 200;
 }
